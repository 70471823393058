import {
  Div,
  Anchor,
  Tag,
  Icon,
  Text
} from 'react-atomize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import '../Styles/FontAwesomeStyles.css';
import Card from "../Components/Card.js";
import YouTube from 'react-youtube';
import InfiniteScroll from "react-infinite-scroller";

function getDateTime(str) {
  let date = new Date(str);
  return date.toDateString() + ' ' + date.toTimeString();
}

function renderCoding(props) {
  return (
    <Card key={props.index} cardType={props.feed.feedType} title={props.feed.subject}>
        <iframe src={props.feed.link + "/embed"}
        allowtransparency="true" 
        width="100%"  
        height="400px"
        frameBorder="0" 
        scrolling="no" 
        allowFullScreen={true}
        title={props.feed.subject} />
      <Div d="flex"
        flexDir="row"
        m="2rem"
        justify="space-between">
        <Div p="0 0.5em" w="500px">
          <Text textSize="caption" textColor="black100">{ getDateTime(props.feed.datetime) }</Text>
          <Text>{props.feed.description}</Text>
        </Div>
        <Anchor href={props.feed.link}
          target="_blank">
            <Tag
                hoverBg="info200"
                m={{ r: "1rem", b: "1rem" }}
                prefix={<Icon name="Link" size="12px" m={{ r: "0.25rem" }} />}
                cursor="pointer"
            >
              Go to Project
            </Tag>
        </Anchor>
      </Div>
    </Card>
    
  );
}
function renderYoutube(props) {
  const opts = {
    height: '400px',
    width: '100%'
  };
  return (
    <Card key={props.index} cardType={props.feed.feedType} title={props.feed.subject}>
      <YouTube videoId={props.feed.id} opts={opts} />
      <Div d="flex"
        flexDir="row"
        m="2rem"
        justify="space-between">
        <Div p="0 0.5em" w="500px">
          <Text textSize="caption" textColor="black100">{ getDateTime(props.feed.datetime) }</Text>
          <Text>{props.feed.description}</Text>
        </Div>
        <Div d="flex"
          flexDir="column">
          <Div d="flex" flexDir="row" align="center" p={{b: "0.5rem"}}>
            <FontAwesomeIcon icon={faEye} color={props.theme.colors.brand800} size="sm" className="prefixButton" />
            <Text m={{ r:'1rem' }}>{props.feed.content[0]['media:community'][0]['media:statistics'][0].entry.views}</Text>
            <FontAwesomeIcon icon={faThumbsUp} color={props.theme.colors.brand800} size="sm" className="prefixButton"/>
            <Text>{props.feed.content[0]['media:community'][0]['media:starRating'][0].entry.count}</Text>
          </Div>
          <Anchor href={props.feed.link}
            target="_blank">
              <Tag
                  hoverBg="info200"
                  m={{ r: "1rem", b: "1rem" }}
                  prefix={<Icon name="Link" size="12px" m={{ r: "0.25rem" }} />}
                  cursor="pointer"
              >
                Go to Youtube
              </Tag>
          </Anchor>
        </Div>
      </Div>
    </Card>
  );
}
function renderPrismic(props) {
  return (
    <Card key={props.index} cardType={props.feed.feedType} title={props.feed.subject}>
      <div 
        dangerouslySetInnerHTML={{ __html: props.feed.content.add_media.html.replace(/(width=")\d+("\W+height=")\d+/, '')}} 
        className="prismicContent" 
        style={{display: "flex", "flexDirection": "column", "alignItems": "center"}}></div>
      <Div d="flex"
        flexDir="row"
        m="2rem"
        justify="space-between">
        <Div p="0 0.5em" w="500px">
          <Text textSize="caption" textColor="black100">{ getDateTime(props.feed.datetime) }</Text>
          <Text>{props.feed.description}</Text>
        </Div>
        <Div d="flex"
          flexDir="column">
          <Anchor href={props.feed.content.add_media.embed_url}
            target="_blank">
              <Tag
                  hoverBg="info200"
                  m={{ r: "1rem", b: "1rem" }}
                  prefix={<Icon name="Link" size="12px" m={{ r: "0.25rem" }} />}
                  cursor="pointer">
                Go to Youtube
              </Tag>
          </Anchor>
        </Div>
      </Div>
    </Card>
  );
}
function RenderFeed(props) {
  if (props.feed.source === 'prismic') {
    return renderPrismic(props);
  } else if (props.feed.feedType === 'coding') {
    return renderCoding(props);
  }
  else if (props.feed.feedType === 'youtube') {
    return renderYoutube(props);
  }

  return (
      <Div></Div>
  );
}



function MainFeed(props) {
  return (
  <Div d="flex" flexDir="column" align="center">
    <InfiniteScroll
      pageStart={0}
      loadMore={props.fetchMore}
      hasMore={props.hasMore()}
      loader={<Text textSize="subheader" textColor="brand800" key={0}>Loading...</Text>}
      useWindow={false}
      getScrollParent={() => document.getElementById('mainHome')}
    >
    { props.feeds.map((feed, index) => <RenderFeed feed={feed} key={index} index={index} theme={props.theme} />) }
    </InfiniteScroll>
  </Div>
  );
}

export default MainFeed;
  