import {
  Div,
  Button
  } from 'react-atomize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faGamepad, faCode, faCubes, faCheck } from '@fortawesome/free-solid-svg-icons';
import '../Styles/FontAwesomeStyles.css';
  
  function FeedHeader(props) {
    const handleFilter = (feedType) => {
      props.onHandleFilter(feedType);
    };

    return (
    <Div border={{ t: "1px solid", l: "1px solid", b: "1px solid", r: "1px solid" }} 
      borderColor="brand200" 
      bg="white"
      d="flex"
      flexDir="row"
      p="1rem"
      w="100%">
        <Button
          prefix={
            <FontAwesomeIcon icon={faCheck} className="prefixButton" />
          }
          shadow="1"
          bg="dark"
          hoverShadow="3"
          onClick={handleFilter.bind(this, '')}
          m={{ r: "0.5rem" }}
        >
          All
        </Button>
        <Button
          prefix={
            <FontAwesomeIcon icon={faCubes} className="prefixButton" />
          }
          shadow="1"
          bg="brand800"
          hoverShadow="3"
          onClick={handleFilter.bind(this, 'lego')}
          m={{ r: "0.5rem" }}
        >
          Lego
        </Button>
        <Button
          prefix={
            <FontAwesomeIcon icon={faGamepad} className="prefixButton" />
          }
          shadow="1"
          bg="brand400"
          hoverShadow="3"
          onClick={handleFilter.bind(this, 'game')}
          m={{ r: "0.5rem" }}
        >
          Game
        </Button>
        <Button
          prefix={
            <FontAwesomeIcon icon={faCode} className="prefixButton" />
          }
          shadow="1"
          bg="brand500"
          hoverShadow="3"
          onClick={handleFilter.bind(this, 'coding')}
          m={{ r: "0.5rem" }}
        >
          Coding
        </Button>
        <Button
          prefix={
            <FontAwesomeIcon icon={faFutbol} className="prefixButton" />
          }
          shadow="1"
          bg="brand300"
          hoverShadow="3"
          onClick={handleFilter.bind(this, 'soccer')}
          m={{ r: "0.5rem" }}
        >
          Soccer
        </Button>
    </Div>
    );
  }
  
  export default FeedHeader;
  