import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { 
  ThemeProvider, 
  DefaultTheme,
  StyleReset } from 'react-atomize';
import App from './App';
import './index.css';
// Disable defaults
// import reportWebVitals from './reportWebVitals';

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    brand800: "#005bb0",
    brand700: "#006bcf",
    brand600: "#0084ff",
    brand500: "#64018f",
    brand400: "#007840",
    brand300: "#d95300",
    brand200: "rgba(0,0,0,0.25)",
    mainBg: "#f5f5f5"
  },
  rounded: {
    ...DefaultTheme.rounded,
    brandRadius: "8px"
  },
  fontFamily: {
    secondary: "'Dancing Script', cursive"
  },
  shadows: {
    ...DefaultTheme.shadows,
    bottomShadow: "0 0 2px 0 rgba(0, 0, 0, 0.5)"
  }
}


const engine = new Styletron();
ReactDOM.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <ThemeProvider theme={theme}>
        <StyleReset />
        <App theme={theme} />
      </ThemeProvider>
    </StyletronProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
