function MainFeed (
    subject, 
    content, 
    description, 
    datetime, 
    link, 
    feedType,
    id,
    source) {

    return {
        subject: subject,
        content: content,
        description: description,
        datetime: datetime,
        link: link,
        feedType: feedType,
        id: id,
        source: source
    };
}

export default MainFeed;