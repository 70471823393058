import React from 'react';
import {
    Div,
    Row
  } from 'react-atomize';

import FeedHeader from '../Components/FeedHeader.js';
import MainFeed from '../Components/MainFeed.js';
import NewsBar from '../Components/NewsBar.js';
import ApiHelper from '../Lib/ApiHelper.js';
import { useState, useEffect } from 'react';

function Home(props) {
  const fetchCount = 5;
  const [allFeeds, setAllFeeds] = useState([]);
  const [mainFeeds, setMainFeeds] = useState([]);
  const [filter, setFilter] = useState('');

  const handleFilter = (feedType) => {
    setFilter(feedType);

    let result = [];
    let maxCount = Math.min(fetchCount, allFeeds.length);
    let endIndex = maxCount;
    let index = 0;
    while (index < endIndex && index < allFeeds.length) {
      if (feedType === '') {
        result.push(allFeeds[index]);
      } else if (feedType === allFeeds[index].feedType) {
        result.push(allFeeds[index]);
      }      

      index++;
      if (result.length < maxCount)
        endIndex++;
    }
    setMainFeeds(result);
  }

  const fetchMore = () => {
    if (!hasMore()) return;

    let newIndex = Math.min(mainFeeds.length + fetchCount, allFeeds.length);
    let newResult = allFeeds.slice(0, newIndex)
    let filtered = newResult.filter(feed => filter === '' || feed.feedType === filter);
    setMainFeeds(filtered);
  }

  const hasMore = () => {  
    let filteredLenght = allFeeds.filter(feed => filter === '' || feed.feedTy === filter).length;
    return (filteredLenght > mainFeeds.length);
  }

 

  useEffect(() => {
    const fetchData = async() => {
      let result = [];
      let values = await Promise.all([
        ApiHelper.callApi('scratch'), 
        ApiHelper.callApi('youtube'),
        ApiHelper.callPrismic('main_content')]);
  
        values.forEach(element => {
          if (!element) return;
  
          element.forEach(feed => {
            if (!feed) return;
            result.push(feed);
          });
        });
  
        let sorted = result.sort((a, b) => 
          Date.parse(b.datetime) - Date.parse(a.datetime));
    
        let slice = Math.min(40, sorted.length);
        let items = sorted.slice(0, slice);
        // let filtered = items.filter(feed => filter === '' || feed.feedType === filter);
        setAllFeeds(items.slice());
        setMainFeeds(items.slice(0, 4));
    };

    fetchData();
  }, []);
  

  return (
    <Div 
      id="mainHome"
      p="70px 0 120px 0" 
      d="flex" 
      w="100%" 
      align="flex-start" 
      justify="center" 
      flexDir="row" 
      overflow="auto"
      h="100%"
      min-height="100%">
      <Row w="100%" maxW="975px" d="flex" flexDir="row">
        <Div className="home-left" d="flex" flexDir="column" w="720px">
          <FeedHeader onHandleFilter={handleFilter} />
          <MainFeed feeds={mainFeeds} fetchMore={fetchMore} hasMore={hasMore} theme={props.theme} />
        </Div>
        <Div className="home-right" d="flex" flexDir="column" size="2" minW="200px">
          <NewsBar />
        </Div>
      </Row>
    </Div>    
  );
}

  
export default Home;
  