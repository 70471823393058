import Prismic from '@prismicio/client';
import MainFeedModel from '../Models/MainFeedModel.js';
const funcs = {
    getApiUrl() {
        if (process.env.NODE_ENV === 'production' || process.env.VERCEL_ENV === 'productions')
            return 'https://creativewj.com/api';
            // return process.env.API_URL;
        return '/api';
    },

    callApi: async(path, params) => {
        const apiUrl = funcs.getApiUrl();
        const requestPath = apiUrl + '/' + path;
        const response = await fetch(requestPath);
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        
        return body;
          
    },

    callPrismic: async(docType) => {
        // docType: 'what_i_follow' | 'main_content'
        const apiEndpoint = 'https://creativewj.cdn.prismic.io/api/v2';
        const accessToken = 'MC5YX2VoOWhBQUFDVUF2czJw.77-977-9TO-_ve-_ve-_ve-_ve-_ve-_vQ3vv73vv73vv71zO1AC77-9LH7vv73vv73vv71P77-977-977-9Kwbvv73vv71_';
      
        const Client = Prismic.client(apiEndpoint, { accessToken });
        const response = await Client.query(
            Prismic.Predicates.at('document.type', docType)
        );

        let items = [];
        if (response) {
            items = response.results.map((item) => {
                let body = item.data.body.length > 0 ? item.data.body[0] : {};

                let model = new MainFeedModel(
                    (item.data.title.length > 0) ? item.data.title[0].text : '',
                    body.primary,
                    body.primary['description1'].length > 0 ? body.primary['description1'][0].text: '',
                    item.last_publication_date,
                    '',
                    item.data.select_type.toLowerCase(),
                    item.id,
                    'prismic'
                );
                return model;
            });
        }

        return items;
    }
}
  
export default funcs;
    