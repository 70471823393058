import {
    Div,
    Text
    } from 'react-atomize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faGamepad, faCode, faCubes, faVideo } from '@fortawesome/free-solid-svg-icons';
import '../Styles/FontAwesomeStyles.css';

function CardBand(props) {
    let bgColor = 'brand800'; 
    let faIcon;
    switch (props.cardType) {
        case 'coding':
            bgColor = 'brand500';
            faIcon = faCode;
            break;
        case 'lego':
            bgColor = 'brand800';
            faIcon = faCubes;
            break;
        case 'game':
            bgColor = 'brand400';
            faIcon = faGamepad;
            break;
        case 'soccer':
            bgColor = 'brand300';
            faIcon = faFutbol;
            break;
        case 'youtube':
            bgColor = 'brand800';
            faIcon = faVideo;
            break;
        default:
            bgColor = 'brand800';
            faIcon = faCode;
    }

    return (
        <Div p="0.5rem" 
            d="flex"
            flexDir="row"
            align="center"
            bg={bgColor}
            rounded={{ tl: "md", tr: "md" }}>
            <FontAwesomeIcon icon={faIcon} color="white" size="2x" className="prefixButton" />
            <Text textSize="subheader" textColor="white">{props.title}</Text>
        </Div>
    );
}

    
function Card(props) {
    return (
    <Div 
        borderColor="brand200"
        rounded="md"
        bg="white" 
        d="flex"
        flexDir="column"
        m={{ t: "2rem" }}
        w="100%">
        <CardBand cardType={props.cardType} title={props.title} />
        <Div d="flex"
            flexDir="column">
            {props.children}
        </Div>
    </Div>
    );
}

export default Card;
