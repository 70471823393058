import Home from './Pages/Home';
import Header from './Components/Header.js';
import {
  Div,
} from 'react-atomize';

function App(props) {
  return (
    <Div className="Main" 
      d="flex" 
      bg="mainBg" 
      flexDir="column"
      h="100%"
      minH="100%">
        <Home theme={props.theme}/>
        <Header />
    </Div>
  );
}

export default App;
