import {
  Div,
  Anchor,
  Image,
  Tag,
  Icon,
  Text
} from 'react-atomize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import '../Styles/FontAwesomeStyles.css';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { useState, useEffect } from 'react';

function NewsBar(props) {
  const [docs, setDocs] = useState([]);

  const apiEndpoint = 'https://creativewj.cdn.prismic.io/api/v2';
  const accessToken = 'MC5YX2VoOWhBQUFDVUF2czJw.77-977-9TO-_ve-_ve-_ve-_ve-_ve-_vQ3vv73vv73vv71zO1AC77-9LH7vv73vv73vv71P77-977-977-9Kwbvv73vv71_';

  useEffect(() => {
    const fetchData = async () => {
      const Client = Prismic.client(apiEndpoint, { accessToken });
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'what_i_follow')
      );
      if (response) {
        let items = response.results.map((item) => {
          let obj = {};
          obj['id'] = item.id;
          obj['title'] = (item.data.title.length > 0) ? item.data.title[0].text : '';
          obj['image'] = item.data.image.url ?? '';
          obj['link'] = item.data.link ? item.data.link.url : '';
          obj['description'] = (item.data.description.length > 0) ? item.data.description : null;
  
          return obj;
        });
        setDocs(items);
      }
    }

    fetchData();
  }, []);
  
  


  return (
  <Div p="1rem 1.5rem"
      d="flex"
      flexDir="column"
      align="flex-start">
    <Div
      d="flex"
      p={{ b: "1rem" }}
      flexDir="row"
      align="center">
        <FontAwesomeIcon icon={faHeart} color={`brand500`} size="sm" className="prefixButton" />
        <Text textColor="black500" 
          textSize="title"
          p={{ l: "0.25rem" }} 
          textWeight="500">What I Follow</Text>
      </Div>

      <Div>
      { 
        docs.map((doc, index) => 
          <Div key={index} index={index} bg="white" shadow="3">
            <Div w="100%" bg="black700" p="0.25rem 0.5rem"><Text textSize="paragraph" textColor="white">{doc.title}</Text></Div>
            { doc.image ? 
              <Image src={doc.image} w="200px" />
            : <Text></Text>
            }

            <Div w="200px" p="0 0.5rem">
              <Div><RichText render={doc.description} /></Div>

              <Anchor 
                href={doc.link}
                textSize="tiny"
                target="_blank">
                  <Tag
                      hoverBg="info200"
                      m={{ r: "1rem", b: "1rem" }}
                      prefix={<Icon name="Link" size="12px" m={{ r: "0.25rem" }} />}
                      cursor="pointer"
                  >
                    Check it out
                  </Tag>
              </Anchor>
            </Div>
          </Div>
        ) 
      }
    </Div>

  </Div>
  );
}

export default NewsBar;
  