import {
    Text,
    Div,
  } from 'react-atomize';
import logo from '../wj_logo.jpg';
  
  function Home() {
    return (
    
      <Div pos="fixed" 
        top="0" 
        d="flex" 
        m="0" 
        w="100%" 
        align="center" 
        justify="center" 
        flexDir="row" 
        style={{ "zIndex": "3" }} 
        bg="white" 
        shadow="bottomShadow"> 
        <Div d="flex" 
          flexDir="row" 
          maxW="975px" 
          w="100%" 
          p="0.5rem 0">
            <Div tag="header" 
              w="100%" 
              pos="relative" 
              d="flex" 
              flexDir="row" 
              m="0" 
              p="0">
              <Div d="flex" flexDir="row" justify="flex-start">
                <img src={logo} alt={"logo"} height="46px" />
                <Text fontFamily="secondary" textSize="display1" m="0 0 0 8px">Creative</Text><Text fontFamily="secondary" textWeight="800" textSize="display1">WJ</Text>
              </Div>
            </Div>
        </Div>
      </Div>
    
    );
  }
  
  export default Home;
  